import * as firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyB1kf6IWXikQoD2ajTqSJHiRdXakshUuLM",
  authDomain: "admin-panel-132.firebaseapp.com",
  databaseURL: "https://admin-panel-132.firebaseio.com",
  projectId: "admin-panel-132",
  storageBucket: "admin-panel-132.appspot.com",
  messagingSenderId: "564211165139",
  appId: "1:564211165139:web:8c28e4798d572a92"
};

class FunctionFirebase {
  constructor(){
    firebase.initializeApp(config);
    this.provider = new firebase.auth.GoogleAuthProvider();
  }

  logIn(){
    // firebase.auth().signInWithRedirect(this.provider)
    firebase.auth().signInWithEmailAndPassword('lirio@inlabdigital.com', '123456')
    .then(() => console.log('se conecto'))
    .catch(error => console.log(error.message));
  }

  logOut() {
    firebase.auth().signOut()
		.then(() => console.log('se desconecto'))
		.catch(error => console.log(error.message))
  }

  isConnected() {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        user ? resolve(true) : resolve(false); // user && !user.isAnonymous
      });
    });
  }

  getUser() {
    // let user = firebase.auth().currentUser;
    // console.log(user);
    // if (user) {
    //   console.log(user);
    // } else {
    // }
  }

  auth() {
    // firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
    //   console.log(idToken, 'token');
		// }).catch(function(error) {
		// 	console.log(error.message);
    // });
    
    firebase.auth().currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      console.log(idTokenResult);
      //  if (!!idTokenResult.claims.admin) {
      //    // Show admin UI.
      //  } else {
      //    // Show regular user UI.
      //    showRegularUI();
      //  }
    })
    .catch((error) => {
      console.log(error);
    });
  }

}

export default new FunctionFirebase();