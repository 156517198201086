// Dependencies
import React, { useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useOutsideAlerter from '../../../hooks/OutSideClick';
import firebase from '../../../hooks/Firebase';

// Components
import ItemsMenu from '../items';
import Avatar from '../../Avatar';

// Styles
import './index.scss'

function Header(props) {
  let mainSectionMenu, generalSectionMenu, sectionLogo = null;
  const { mainMenu, generalMenu, logo } = props.data;
  const userProfile = props.userProfile;

  const [prevItemNameClicked, setValue] = useState('');
  const [isSubMenuOpen, setOpen] = useState(false);
  const [itemClickEvent, setItemClickedEvent] = useState(false);
  const wrapperRef = useRef(null);
  
  const handlerClick = (currentItemNameClicked) => {
    let setSubMenuOpen = currentItemNameClicked === prevItemNameClicked && !isSubMenuOpen ? true : false;
    setItemClickedEvent(true);
		setValue(currentItemNameClicked);
    setOpen(setSubMenuOpen);
  }
  
  const handlerOutSideClick = () => {
    if(itemClickEvent) {
			setItemClickedEvent(false);
			setOpen(true);
		}
  };

  const handlerClickLogOut = () => {
    firebase.logOut();
  }
  
  if(logo) {
    sectionLogo = <div className="wrapper__nav__logo">
      <img src={logo} alt="Inlab Digital"/>
    </div>
  }
  
  if(mainMenu && mainMenu.length > 0) {
    const mainMenuItems = mainMenu.map( item =>
      <ItemsMenu
        item={item.name}
        to={`/${item.name}`}
        subMenu={item.subMenu}
        key={`item-${item.name}`}
        handlerClickExpandedSubmenu={handlerClick}
        itemClicked={prevItemNameClicked}
        itemExpanded={isSubMenuOpen}
      />
    );
      
    mainSectionMenu = <div className="wrapper__nav__section">
      <ul ref={wrapperRef}>
        { mainMenuItems }
      </ul>
    </div>
  }
  
  if(generalMenu && generalMenu.length > 0) {
    const generalMenuItems = generalMenu.map( item =>
      <ItemsMenu
        item={item.name}
        to={`/${item.name}`}
        subMenu={item.subMenu}
        key={`item-${item.name}`}
      />
    );
      
    generalSectionMenu = <div className="wrapper__nav__section wrapper__nav__section--second">
      <ul>
        { generalMenuItems }
        <li>
          <div className="wrapper__nav__section__profile">
            <Avatar userProfile={userProfile} />
            <label className="wrapper__nav__section__profile__name">{userProfile.name}</label>
          </div>
        </li>
        <li onClick={handlerClickLogOut}>
          <button className="wrapper__nav__section__logout">Logout</button>
        </li>
      </ul>
    </div>
  }
  
  useOutsideAlerter(wrapperRef, handlerOutSideClick);

  return (
    <div hidden={props.hidden} className="wrapper__nav">
      { sectionLogo }
      { mainSectionMenu }
      { generalSectionMenu }
    </div>
  );
};

Header.propTypes = {
	data: PropTypes.shape({
		mainMenu: PropTypes.array.isRequired,
		logo: PropTypes.string.isRequired
  })
};

Header.defaultProps = {
  hidden: false
}

export default Header;
