import { createStore, combineReducers } from 'redux';
import userState from './reducers/userState';

const reducer = combineReducers({
  userState
});

const store = createStore(reducer);

export default store;

