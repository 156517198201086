// Dependencies
import React from 'react';
import firebase from '../../hooks/Firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import getUserState from '../../redux/actions/getUserState';

// Constants
import { ROUTES } from '../../constants';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.handlerClick = this.handlerClick.bind(this);
	}

	handlerClick() {
		firebase.logIn();
		this.props.getUserState(true);
		this.props.history.push(ROUTES.home)
	}

	render () {
		return (
			<div className="wrapper__login">
        <div>LOGIN</div>
				<button onClick={this.handlerClick}>Login</button>
			</div>
		)
	};
}

const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.userState
	}
}

const mapDispatchToProps = {
	getUserState
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));