// Dependencies
import React from 'react';

// Style
import './index.scss';

function Spinner(props){
  return (
    <div className={`spinner ${props.type} ${props.active ? 'active' : ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;


