export const ROUTES = {
  home: "/",
  login: "/login"
}

export const MAIN_MENU = [
  {name: 'Offer'},
  {name: 'Campaign'},
  {name: 'Publisher'},
  {name: 'Advertiser', icon:'', subMenu: [
    {name: 'item-1'},
    {name: 'item-2'},
    {name: 'item-3'},
    {name: 'item-4'}
  ]},
  {name: 'Report', icon: '', subMenu: [
    {name: 'item-3'},
    {name: 'item-4'}
  ]},
]

export const PROFILE_MENU = [
  {name: 'Settings', icon: 'icon'}
]
