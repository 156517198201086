import { type as getUserState } from '../actions/getUserState';

const defaultState = null;

function reducer(state = defaultState, { type, payload }){
  switch(type) {
    case getUserState: {
      return payload
    }
    default:
      return state;
  }
}

export default reducer;