// Dependencies
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import AppRoutes from '../../routes';
import Spinner from '../../components/Spinner';
import Header from '../../components/Header/Wrapper';
import LOGO from '../../images/logo_color.png';
import firebase from '../../hooks/Firebase';
import getUserState from '../../redux/actions/getUserState';

// Constants
import { MAIN_MENU, PROFILE_MENU, ROUTES } from '../../constants';

// Styles
import '../../shared-styles/styles.scss';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: true		
		};
	}

	componentDidMount() {
		firebase.isConnected()
		.then(result => {
			this.props.getUserState(result);
			this.setState({ loader: false })
			this.selectPage();
		})
	};
			
	selectPage() {
		const route = this.props.location.pathname;
		this.props.isLoggedIn ? this.props.history.push(route) : this.props.history.push(ROUTES.login);
	}
	
	render() {
		const dataHeader = {
			mainMenu: MAIN_MENU,
			generalMenu: PROFILE_MENU,
			logo: LOGO
		}

		//Temporal
		const userProfile = {
			img: "",
			name: "Lirio De Biase"
		}
				
		if(this.props.isLoggedIn === null){
			return (
				<Spinner active={this.state.loader} type="spinner__circle"/>
			)
		}else {
			return (
				<div className="wrapper__app">
					<Header
						hidden={this.props.location.pathname !== ROUTES.login ? false : true}
						data={dataHeader}
						userProfile={userProfile}
					/>
					<AppRoutes/>
				</div>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.userState
	}
}

const mapDispatchToProps = {
	getUserState
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));