// Dependencies
import React, { useState, useEffect }from 'react';

//Style
import './index.scss';

function Avatar(props) {
  const [nameInitials, setNameInitials] = useState("");
  const [colorValue, setColorValue] = useState("#15BEEA");

  useEffect(() => {
    getName();
    setColor(); 
  });

  function getName(){
    let initials = "";
    props.userProfile.name.replace(/[a-z]{2,}/gi,function(item){
      if(initials.length < 2){
        initials+=item.charAt(0)
      }
    });
    setNameInitials(initials.toUpperCase());
  }

  function setColor() {
    let index =  Math.floor(Math.random() * 5);
    switch(index){
      case 1:
        setColorValue("#FFCCCC");
        break;
      case 2:
        setColorValue("#CCAFFF");
        break;
      case 3:
        setColorValue("#A6BEFF");
        break;
      case 4:
        setColorValue("#99FFFF");
        break;
      case 5:
        setColorValue("#D5CCBB");
        break;
      default:
        setColorValue("#15BEEA");
    }
  }

  return (
    <div className="wrapper__avatar" style={{background: colorValue}}>
      <label>{nameInitials}</label>
    </div>
  );
  
}
export default Avatar;
