// Dependencies
import React from 'react';

class ComponentView extends React.Component {
	render () {
		return (
			<div className="wrapper__view">
				HOME
			</div>
		)
	};
}

export default ComponentView;