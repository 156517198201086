// Dependencies
import React from 'react';

// Components
import { NavLink } from 'react-router-dom';

// Styles
import './index.scss';

class Link extends React.Component {
	render () {

		return (
      <NavLink
        to={ this.props.to }
        activeClassName={ this.props.activeClassName }
      >
        <label>{ this.props.label }</label>
      </NavLink>
    );
  }
}

Link.defaultProps = {
  activeClassName: "active",
  label: "Link"
}

export default Link;