// Dependencies
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// Components
import ComponentView from '../views/component-view';
import Login from '../views/Login';

import Spinner from '../components/Spinner'

// Constants
import { ROUTES } from '../constants';

const AppRoutes = () =>
	<Switch>
		<Route exact path={ROUTES.home} component={ComponentView} />
		<Route exact path="/app" component={Spinner} />
		<Route exact path={ROUTES.login} component={Login} />
	</Switch>

export default withRouter(AppRoutes);

