 // Dependencies
 import React from 'react';

 // Component
 import Link from '../../Link';
 
 function ItemsMenu(props) {  
  
  const handlerInSideClick = (nameItemClicked) => {
    props.handlerClickExpandedSubmenu(nameItemClicked);
  };
  
  let subMenuItems;

  if(props.subMenu && props.subMenu.length) {
    subMenuItems = <div
      className="wrapper__nav__section__submenu"
      hidden={props.item === props.itemClicked && !props.itemExpanded ? false : true}
    >
      <ul>
        {props.subMenu.map( item => 
          <li key={`subItem-${item.name}`}>
            <Link 
              label={item.name}
              to={`/${item.name}`}
            />
          </li>
        )}
      </ul>
    </div>
  }

  return (
    <li 
      onClick={() => handlerInSideClick(props.item)}
    >
      <Link
        label={props.item}
        to={props.subMenu ? "" : props.to}
      />
      { subMenuItems }
    </li>
  );
 }
 
 export default ItemsMenu;
 